import { FC } from 'react';

import { SectionContainer, SectionTitle } from '~/components/Section';
import { Centralized } from '~/components/Centralized';

import { Content } from './styles';

export const Reasons: FC = () => {
  return (
    <SectionContainer
      id="reasons"
      style={{
        background:
          'linear-gradient(to right, #eaeaea, #e4e4e4, #dddddd, #d7d7d7, #d1d1d1)',
      }}
    >
      <Centralized>
        <Content>
          <SectionTitle centralized textColor="onLightBackground" size="lg">
            Por que aderir a Plataforma Facilita Varejo?
          </SectionTitle>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

import styled from 'styled-components';

export const IntegrationTextsContainer = styled.div`
  margin-bottom: 4rem;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const IntegrationNumber = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font: 400 5rem 'Russo One';
  line-height: 4.5rem;

  margin-right: 0.7rem;

  /* @media (max-width: 900px) {
    display: none;
  } */
`;

export const IntegrationTitle = styled.h1`
  color: ${({ theme }) => theme.colors.onDarkBackground};
  font-size: 1.4rem;
  line-height: 2rem;

  .color-secondary {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const IntegrationDescription = styled.p`
  color: ${({ theme }) => theme.colors.onDarkBackground};
  font-size: 0.95rem;

  margin-top: 0.3rem;
`;

export const IntegrationFloatText = styled.div`
  position: absolute;

  background: rgba(24, 24, 24, 0.75);
  padding: 0.8rem 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  color: ${({ theme }) => theme.colors.tertiary};

  .icon {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.borderRadius.rounded};

    width: 2.2rem;
    height: 2.2rem;

    background: ${({ theme }) => theme.colors.primary};

    svg {
      font-size: 1.15rem;
    }

    p {
      font-size: 0.62rem;
      text-align: center;
      margin-top: 0;
    }

    &.top-left {
      left: -0.9rem;
      top: -0.9rem;
    }

    &.top-right {
      right: -0.9rem;
      top: -0.9rem;
    }
  }

  h1 {
    font-size: 0.85rem;
  }

  p {
    font-size: 0.7rem;
    font-weight: 300;
    margin-top: 0.2rem;
  }
`;

export const IntegrationStylezedText = styled.div`
  position: relative;

  padding: 1.5rem 3rem;
  border-radius: 50%;
  border: ${({ theme }) => `2px solid ${theme.colors.onDarkBackground}`};

  color: ${({ theme }) => theme.colors.onDarkBackground};
  white-space: nowrap;
  font-weight: 300;

  font: 400 1.1rem 'Courgette';

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;

    height: 4rem;
    width: 98%;
    border: ${({ theme }) => `2px solid ${theme.colors.onDarkBackground}`};
    border-radius: 50% 60% 50% 50%;

    transform: rotate(3deg);
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

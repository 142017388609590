import { FC } from 'react';

import { FaShareAlt } from 'react-icons/fa';

import { useMediaQuery } from '~/hooks/mediaQuery';

import { SectionContainer } from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import {
  Content,
  FirstGroup,
  ImageContainer,
  LastGroup,
  SolutionContent,
} from './styles';
import {
  IntegrationNumber,
  IntegrationTextsContainer,
  IntegrationTitle,
} from '../styles';

export const MenuFacil: FC = () => {
  const isBiggerThan900px = useMediaQuery('(min-width: 900px)');

  return (
    <SectionContainer>
      <Centralized>
        <Content>
          <FirstGroup>
            <IntegrationNumber>3</IntegrationNumber>

            <IntegrationTextsContainer>
              <IntegrationTitle>
                O queridinho dos restaurantes é nosso!
                {isBiggerThan900px && <br />} A Plataforma Facilita Varejo
                disponibiliza integração direta com o{' '}
                <span className="color-secondary">app de cardápio digital</span>{' '}
                MENU FÁCIL, {isBiggerThan900px && <br />}você só precisa
                cadastrar os seus produtos e já pode utilizar.
              </IntegrationTitle>
            </IntegrationTextsContainer>
          </FirstGroup>

          <LastGroup>
            <ImageContainer>
              <Image
                src="/images/integrations/menu-facil/phone.png"
                alt="Menu Fácil"
              />

              <div className="side-text">
                <h1>KDS disponível</h1>
                <p>Visualize os pedidos no monitor da cozinha</p>
              </div>
            </ImageContainer>

            <SolutionContent>
              <div className="card">
                <header>
                  <h1>Cardápio digital</h1>

                  <FaShareAlt />
                </header>

                <p>
                  Com o cardápio digital Menu Fácil, você pode facilitar o
                  pedido do seu cliente com apenas um link. Nesse link, o
                  cliente acessa o seu cardápio, realiza o pedido e faz o
                  pagamento. Sem burocracia e taxas adicionais. Você pode
                  compartilhar esse link pelo WhatsApp, Facebook, Instagram e
                  outras redes sociais.
                </p>
              </div>

              <Button onClick={() => goTo('prices')}>Quero contratar</Button>
            </SolutionContent>
          </LastGroup>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

import styled from 'styled-components';

export const PlansContainer = styled.section`
  width: 100%;
  background: #e6e7e8;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  margin: 100px 0;
  max-width: 1300px;

  header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;

    width: 100%;
    padding: 0 30px;

    margin-bottom: 50px;

    .title {
      color: ${({ theme }) => theme.colors.primary};

      h2 {
        font-size: 30px;
      }

      h1 {
        font-size: 40px;
        font-weight: 800;
      }
    }

    .plan-type {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-bottom: 8px;
        font-size: 14px;
      }

      button {
        position: relative;

        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 50px;

        background: #fff;

        div {
          padding: 13px 45px;

          color: ${({ theme }) => theme.colors.primary};
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 0.5px;

          z-index: 2;
        }

        .blur {
          position: absolute;
          right: 0;

          background: ${({ theme }) => theme.colors.primary};
          border-radius: 50px;
          height: 100%;
          width: 50%;
          z-index: 1;
        }

        .right {
          right: 0;
        }

        .left {
          left: 0;
        }

        .selected {
          color: #fff;
          border-radius: 50px;
        }
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;

      .title {
        text-align: center;
        margin-bottom: 10px;

        h2 {
          font-size: 30px;
        }

        h1 {
          font-size: 35px;
        }
      }
    }
  }
`;

export const PlansTable = styled.table`
  background: #e6e7e8;
  display: flex;
  flex-direction: row;

  border-spacing: 0px;

  padding-left: 30px;
  padding-bottom: 10px;
  margin-right: 30px;

  ::-webkit-scrollbar {
    height: 12px;
    background: #fff;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 1450px) {
    overflow: auto;
  }

  @media (max-width: 920px) {
    margin-right: 0;
    padding-right: 50px;
  }

  tr {
    width: 100%;

    display: flex;
    flex-direction: column;

    & + tr {
      &:hover {
        cursor: pointer;

        td {
          background: #f9f9f9 ;
          transition: background 0.2s;

          &:last-child {
            span {
              transition: transform 0.3s;
              transform: scale(1.1)
            }

            button {
              background: #fff;
              color: ${({ theme }) => theme.colors.secondary};
              border-color: #fff;
              transition: all 0.5s;
            }
          }
        }
      }

      td {
        &:last-child {
          position: sticky;
          position: -webkit-sticky;
          bottom: 0;

          background: ${({ theme }) => theme.colors.secondary};

          display: flex;
          flex-direction: column;
          align-items: center;

          background: ${({ theme }) => theme.colors.secondary};

          white-space: nowrap;

          span {
            font-weight: 700;
            color: #fff;
            font-size: 25px;
          }

          .old-price {
            opacity: 0.8;
            font-size: 11.5px;
            margin-bottom: 5px;
          }

          .price {
            span:last-child {
              font-size: 18px;
            }
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: #fff;
            font-weight: bold;
            font-size: 18px;
            border: 2px solid #eee;
            padding: 4px 30px;
            border-radius: 20px;
          }
        }
      }
    }

    &:first-child {
      th,
      tr {
        text-align: left;
        justify-content: flex-start;
      }

      th {
        font-size: 13px;

        &:first-child {
          background: #e6e7e8;
          padding-left: 0;
          color: #000;
          font-weight: 800;
          font-size: 20px;
        }

        &:last-child {
          background: #e6e7e8;
          padding-left: 0;
          border-bottom: 0;
          color: #666;
          white-space: normal;
        }

        @media (max-width: 600px) {
          font-size: 12px;

          &:first-child {
            font-size: 17px;
          }
        }
      }
    }

    th,
    td {
      display: flex;
      align-items: center;
      justify-content: center;

      background: #fff;

      text-align: center;
      padding: 0 20px;

      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;

      height: 45px;
      white-space: nowrap;

      &:first-child {
        height: 65px!important;
      }

      &:last-child {
        height: auto;
        flex: 1;

        padding: 15px 20px;
      }
      }
    }

    td {
      svg {
        color: ${({ theme }) => theme.colors.secondary};
        font-size: 20px;
      }

      .close-icon {
        svg {
          color: rgba(0, 0, 0, 0.3);
        }
      }

      &:first-child {
        background: ${({ theme }) => theme.colors.primary} !important;
        padding: 0 50px;

        .plan-name {
          color: #fff;
          font-weight: 700;
          font-size: 22px;

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }

        .best-seller {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
              color: #daa520;
              margin-left: 8px;
            }
          }

          p {
            color: #fff;
            font-size: 12.5px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
`;

export const DescriptionContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  z-index: 99;

  svg {
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;

    margin-left: 8px;
    color: #999;
  }

  .tooltip-container {
    position: absolute;
    left: -34px;
    bottom: calc(100% + 12px);

    width: 315px;
    padding: 15px;
    background: #fff;

    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    z-index: 999;

    td {
      background #fff !important;
      border: 0 !important;
      padding: 5px 0px !important;
      margin: 0 !important;
    }

    &:before {
      background: #fff;
      content: '';
      display: block;
      height: 25px;
      width: 25px;
      left: 20px;
      position: absolute;
      bottom: 10px;
      transform: rotate(-45deg) translateY(25px);
      z-index: -5;
    }

    h2 {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 10px;
      color: ${({ color }) => color}!important;
      white-space: normal;
    }

    p {
      line-height: 14px;
      font-size: 13px;

      white-space: normal;

      & + p {
        margin-top: 10px;
        white-space: normal;
      }
    }
  }
`;

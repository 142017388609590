import styled from 'styled-components';

interface INavBarProps {
  isOpen: boolean;
}

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;

  width: 100%;
  background: rgba(10, 10, 10, 0.98);
  height: 4.2rem;
  z-index: 999;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 4.2rem;

  img {
    width: 6rem;
    margin-top: 0.2rem;
  }
`;

export const MenuButton = styled.button`
  svg {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colors.onDarkBackground};
  }
`;

export const NavBar = styled.nav<INavBarProps>`
  button {
    margin-left: 2rem;

    color: ${({ theme }) => theme.colors.tertiary};
    font-size: 0.95rem;
  }

  @media (max-width: 900px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    padding: 10rem 0;
    background: rgba(0, 0, 0, 0.92);

    button {
      font-size: 1.3rem;
      margin-left: 0;
    }
  }
`;

export const CloseMenuButton = styled.button`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;

  svg {
    color: ${({ theme }) => theme.colors.onDarkBackground};
    font-size: 2.5rem;
  }
`;

import { FC } from 'react';

import { MdAddAlert } from 'react-icons/md';
import { FaPercent } from 'react-icons/fa';

import { SectionContainer } from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import {
  Content,
  FirstGroup,
  ImageContainer,
  LastGroup,
  MarketplacesList,
} from './styles';
import {
  IntegrationFloatText,
  IntegrationNumber,
  IntegrationStylezedText,
  IntegrationTextsContainer,
  IntegrationTitle,
} from '../styles';

export const Marketplaces: FC = () => {
  return (
    <SectionContainer>
      <Centralized>
        <Content>
          <div>
            <FirstGroup>
              <header>
                <IntegrationNumber>2</IntegrationNumber>

                <IntegrationTextsContainer>
                  <IntegrationTitle>
                    Chegou sua vez de multiplicar as suas vendas! Ao contratar a
                    Plataforma Facilita Varejo, você poderá vender nos{' '}
                    <span className="color-secondary">
                      melhores e maiores marketplaces
                    </span>{' '}
                    de forma fácil e rápida.
                  </IntegrationTitle>
                </IntegrationTextsContainer>
              </header>

              <IntegrationStylezedText
                style={{
                  transform: 'rotate(-8deg)',
                }}
              >
                Partiu dar o primeiro passo?
              </IntegrationStylezedText>
            </FirstGroup>

            <LastGroup>
              <ImageContainer>
                <IntegrationFloatText className="order-float">
                  <div className="icon top-left">
                    <MdAddAlert />
                  </div>

                  <h1>Novos pedidos</h1>

                  <p>
                    Você será alertado quando um novo
                    <br /> pedido chega a você
                  </p>
                </IntegrationFloatText>

                <Image
                  src="/images/integrations/marketplaces/background.png"
                  alt="Marketplaces"
                />

                <IntegrationFloatText className="tax-float">
                  <div className="icon top-right">
                    <FaPercent />
                  </div>

                  <h1>% Taxas exclusivas</h1>

                  <p>Buscamos parcerias estratégicas</p>
                </IntegrationFloatText>
              </ImageContainer>

              <MarketplacesList>
                <li>
                  <Image src="/images/logos/shopee-white.png" alt="Shopee" />
                </li>
                <li>
                  <Image
                    src="/images/logos/meli-white.png"
                    alt="Mercado Livre"
                  />
                </li>
                <li>
                  <Image
                    src="/images/logos/americanas-white.png"
                    alt="Americanas"
                  />
                </li>

                <li>
                  <Image src="/images/logos/via-white.png" alt="Via Varejo" />
                </li>
                <li>
                  <Image src="/images/logos/ifood-white.png" alt="IFood" />
                </li>
                <li>
                  <Image src="/images/logos/rappi-white.png" alt="Rappi" />
                </li>

                <li>
                  <Image src="/images/logos/amazon-white.png" alt="Amazon" />
                </li>
                <li>
                  <Image src="/images/logos/magalu-white.png" alt="Magalu" />
                </li>
                <li>
                  <Image
                    src="/images/logos/carrefour-white.png"
                    alt="Carrefour"
                  />
                </li>
              </MarketplacesList>
            </LastGroup>
          </div>

          <footer>
            <Button onClick={() => goTo('prices')}>Quero contratar</Button>
          </footer>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

import styled from 'styled-components';

export const VideoModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 0 2rem;

  @media (max-width: 600px) {
    height: 300px;
  }

  @media (max-width: 500px) {
    height: 270px;
  }
`;

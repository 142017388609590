import styled, { ColorScheme, css } from 'styled-components';

interface ISectionContainerProps {
  backgroundColor?: ColorScheme;
}

interface ISectionTitleProps {
  size?: 'md' | 'lg';
  textColor?: ColorScheme;
  centralized?: boolean;
}

interface ISectionDescriptionProps {
  size?: 'md' | 'lg';
  textColor?: ColorScheme;
  centralized?: boolean;
}

export const SectionContainer = styled.section<ISectionContainerProps>`
  padding: ${({ theme }) => `${theme.sectionSpacing} 0`};

  background: ${({ backgroundColor, theme }) =>
    backgroundColor
      ? theme.colors[backgroundColor]
      : theme.colors.darkBackground};

  @media (max-width: 900px) {
    padding: ${({ theme }) => `${theme.mobileSectionSpacing} 0`};
  }
`;

export const SectionTitle = styled.h1<ISectionTitleProps>`
  color: ${({ textColor, theme }) =>
    textColor ? theme.colors[textColor] : theme.colors.onDarkBackground};

  text-align: ${({ centralized }) => centralized && 'center'};

  .color-secondary {
    color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ size }) =>
    size === 'lg'
      ? css`
          font-size: 2.8rem;
          line-height: 3.2rem;

          b {
            font-size: 4.3rem;
          }

          @media (max-width: 900px) {
            font-size: 3rem;
            line-height: 3.4rem;

            b {
              font-size: 3.5rem;
            }
          }
        `
      : css`
          font-size: 1.9rem;
          line-height: 2.2rem;
        `}
`;

export const SectionDescription = styled.p<ISectionDescriptionProps>`
  margin-top: 0.8rem;

  color: ${({ textColor, theme }) =>
    textColor ? theme.colors[textColor] : theme.colors.onDarkBackground};
  text-align: ${({ centralized }) => centralized && 'center'};

  ${({ size }) =>
    size === 'lg'
      ? css`
          font-size: 1.25rem;
          line-height: 1.4rem;
        `
      : css`
          font-size: 1rem;
          line-height: 1.4rem;
        `}
`;

import { useCallback, useEffect, useState } from 'react';

import type { NextPage } from 'next';

import { Reasons } from '~/sections/Reasons';
import { Presentation } from '~/sections/Presentation';
import { Plans } from '~/sections/Plans';
import { Totem } from '~/sections/Integrations/Totem';
import { TabletFacil } from '~/sections/Integrations/TabletFacil';
import { PraVendas } from '~/sections/Integrations/PraVendas';
import { PdvFacil } from '~/sections/Integrations/PdvFacil';
import { MenuFacil } from '~/sections/Integrations/MenuFacil';
import { Marketplaces } from '~/sections/Integrations/Marketplaces';
import { Help } from '~/sections/Help';
import { Header } from '~/sections/Header';
import { ForYourBusiness } from '~/sections/ForYourBusiness';
import { Footer } from '~/sections/Footer';
import { Benefits } from '~/sections/Benefits';

import { useFacebookPixel } from '~/hooks/facebookPixel';

import { WhatsappButton } from '~/components/WhatsappButton';

const Home: NextPage = () => {
  const { track } = useFacebookPixel();

  const [scrollTop, setScrollTop] = useState<number>(0);
  const [viewed, setViewed] = useState<boolean>(false);

  const onScroll = useCallback(() => {
    setScrollTop(window.pageYOffset);
  }, []);

  useEffect(() => {
    const footerTop = document.getElementById('footer-section')?.offsetTop;
    const footerHeight =
      document.getElementById('footer-section')?.clientHeight;
    const windowScreenHeight = window.screen.height;

    window.addEventListener('scroll', onScroll);

    if (footerTop + footerHeight < scrollTop + windowScreenHeight && !viewed) {
      track('ViewContent');
      setViewed(true);
    }
  }, [onScroll, scrollTop, track, viewed]);

  return (
    <>
      <Header />
      <Presentation />
      <Benefits />
      <ForYourBusiness />

      <Reasons />

      <PdvFacil />
      <Marketplaces />
      <MenuFacil />
      <Totem />
      <PraVendas />
      <TabletFacil />

      <Plans />
      <Help />
      {/* <ReceiveNotifications /> */}
      <Footer />

      <WhatsappButton />
    </>
  );
};

export default Home;

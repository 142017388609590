import { FC } from 'react';

import { FaShoppingCart } from 'react-icons/fa';

import { SectionContainer } from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import { Content, FirstGroup, LastGroup, Pixels } from './styles';
import {
  IntegrationDescription,
  IntegrationNumber,
  IntegrationStylezedText,
  IntegrationTextsContainer,
  IntegrationTitle,
} from '../styles';

export const PraVendas: FC = () => {
  return (
    <SectionContainer>
      <Centralized>
        <Content>
          <FirstGroup>
            <header>
              <IntegrationNumber>5</IntegrationNumber>

              <IntegrationTextsContainer>
                <IntegrationTitle>
                  Integrado ao E-commerce{' '}
                  <span className="color-secondary">PraVendas</span>.
                </IntegrationTitle>

                <IntegrationDescription>
                  A Plataforma Facilita Varejo é integrado automaticamente a
                  plataforma PRA VENDAS, uma solução completa para quem procura
                  um e-commerce confiável e altamente eficiente, o usuário não
                  precisa perder tempo com integrações complicadas para ter a
                  sua loja online.
                </IntegrationDescription>
              </IntegrationTextsContainer>
            </header>

            <IntegrationStylezedText
              style={{
                transform: 'rotate(-8deg)',
              }}
            >
              Aqui o seu site é completo!
            </IntegrationStylezedText>
          </FirstGroup>

          <LastGroup>
            <div className="devices-image">
              <Image
                src="/images/integrations/pra-vendas/devices.png"
                alt="Pra Vendas"
              />
            </div>

            <Pixels>
              <ul>
                <li>
                  <div className="image">
                    <Image
                      src="/images/logos/facebook-pixel.png"
                      alt="Facebook Pixel"
                    />
                  </div>

                  <footer>
                    <span>Facebook Pixel</span>
                  </footer>
                </li>

                <li>
                  <div className="image">
                    <Image
                      src="/images/logos/google-analytics.png"
                      alt="Google Analytics"
                    />
                  </div>

                  <footer>
                    <span>Google Analytics</span>
                  </footer>
                </li>

                <li>
                  <div className="image">
                    <Image
                      src="/images/logos/google-ad.png"
                      alt="Google AdWords"
                    />
                  </div>

                  <footer>
                    <span>Google AdWords</span>
                  </footer>
                </li>

                <li>
                  <div className="image">
                    <Image
                      src="/images/logos/google-tags.png"
                      alt="Tags do Google"
                    />
                  </div>

                  <footer>
                    <span>Tags do Google</span>
                  </footer>
                </li>

                <li>
                  <div className="image">
                    <Image
                      src="/images/logos/jivo.png"
                      alt="Chat Personalizado"
                    />
                  </div>

                  <footer>
                    <span>Chat Personalizado</span>
                  </footer>
                </li>

                <li>
                  <div className="image">
                    <FaShoppingCart />
                  </div>

                  <footer>
                    <span>Checkout Transparente</span>
                  </footer>
                </li>
              </ul>

              <footer>
                <Image
                  src="/images/logos/pra-vendas-white.png"
                  alt="Pra Vendas"
                />

                <Button onClick={() => goTo('prices')}>Quero contratar</Button>
              </footer>
            </Pixels>
          </LastGroup>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

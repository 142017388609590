import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  padding-top: ${({ theme }) => theme.sectionSpacing};
  padding-bottom: 1rem;
  background: ${({ theme }) => theme.colors.darkBackground};

  @media (max-width: 865px) {
    padding-top: 2.2rem;
  }
`;

export const First = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 0.8fr;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border-right: 1px solid ${({ theme }) => theme.colors.onDarkBackground};

    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      border-right: none;
    }

    li {
      padding-right: 1.5rem;

      button {
        color: ${({ theme }) => theme.colors.onDarkBackground};
      }

      h2 {
        font-size: 1rem;

        margin-bottom: 0.2rem;
        color: ${({ theme }) => theme.colors.onDarkBackground};
      }

      p {
        font-size: 0.9rem;
        color: ${({ theme }) => theme.colors.onDarkBackground};
      }
      span {
        color: ${({ theme }) => theme.colors.onDarkBackground};
      }

      .margin-bottom {
        margin-bottom: 0.8rem;
      }
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 0.8rem !important;

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        color: ${({ theme }) => theme.colors.onDarkBackground};
      }

      h1 {
        font-size: 1.1rem;
        color: ${({ theme }) => theme.colors.onDarkBackground};
      }
    }
  }

  .phone-numbers {
    justify-content: flex-start;

    li {
      margin-bottom: 0.3rem;
    }
  }

  .partners-list {
    justify-content: flex-start;

    img {
      margin-top: 12px;
      max-width: 200px;
    }
  }

  @media (max-width: 865px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    ul {
      border: none;
      align-items: center !important;

      li {
        text-align: center !important;

        button {
          margin-bottom: 0.2rem;
        }
      }
    }
  }
`;

export const Last = styled.div`
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 2px solid ${({ theme }) => theme.colors.onDarkBackground};

  .centralized {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      width: 6rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        color: ${({ theme }) => theme.colors.onDarkBackground};
        font-size: 0.9rem;
      }
    }

    @media (max-width: 430px) {
      flex-direction: column;

      > div {
        align-items: center;

        margin-top: 1rem;
      }
    }
  }
`;

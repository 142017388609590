import styled from 'styled-components';

export const Content = styled.div`
  > footer {
    display: flex;
    justify-content: flex-end;

    margin-top: 2rem;
  }
`;

export const FirstGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const LastGroup = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap: 2rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  img {
    width: 60%;
  }

  .ticket-float {
    bottom: 50%;
    left: -5%;
  }

  .nfce-float {
    top: 10%;
    right: 30%;
  }

  .kds-float {
    bottom: 10%;
    right: 20%;
  }

  @media (max-width: 1350px) {
    .ticket-float {
      left: -1%;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;

    img {
      width: 90%;
    }

    .nfce-float {
      right: -1%;
    }

    .kds-float {
      right: -1%;
    }
  }

  @media (max-width: 400px) {
    .ticket-float {
      left: 0;
    }

    .nfce-float {
      right: 0;
    }

    .kds-float {
      right: 0;
    }
  }
`;

export const Equipaments = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background: ${({ theme }) => theme.colors.overshadowed};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: 3rem 1.5rem;

    h1 {
      color: ${({ theme }) => theme.colors.tertiary};
      text-align: center;
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-weight: 400;

      margin-bottom: 2rem;
    }

    @media (max-width: 500px) {
      padding: 3rem 0.5rem;

      h1 {
        font-size: 1.1rem;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 100%;
      max-height: 330px;

      > div {
        img {
          width: 5.5rem;
        }
      }

      .solution {
        &:first-child {
          img {
            width: 4rem;
          }
        }
      }

      .equipament {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 1rem;

        button {
          display: flex;
          flex-direction: row;
          align-items: center;

          background: rgba(256, 256, 256, 0.1);
          padding: 0.3rem 1.5rem;
          border-radius: ${({ theme }) => theme.borderRadius.xl};
          border: ${({ theme }) =>
            `1px solid ${theme.colors.onDarkBackground}`};

          color: ${({ theme }) => theme.colors.onDarkBackground};
          font-size: 0.85rem;

          margin-top: 1.5rem;

          svg {
            font-size: 1.2rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }
`;

import { VideoModalContainer } from './styles';

interface IProps {
  videoUrl: string;
}

const VideoModal: React.FC<IProps> = ({ videoUrl }) => {
  return (
    <VideoModalContainer>
      <iframe
        title="othervideo"
        src={videoUrl}
        width="810"
        height="400"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </VideoModalContainer>
  );
};

export default VideoModal;

import { FC } from 'react';

import { ColorScheme } from 'styled-components';

import { StyledSpinner } from './styles';

interface SpinnerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: ColorScheme;
}

export const Spinner: FC<SpinnerProps> = ({ size = 'md', color = 'text' }) => {
  return <StyledSpinner size={size} color={color} />;
};

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  header {
    img {
      width: 1.5rem;
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-left: auto;
    margin-top: 2rem;
    width: 100%;

    img {
      width: 8rem;
    }
  }

  @media (max-width: 900px) {
    > div {
      grid-template-columns: 1fr;
    }

    footer {
      width: 100%;
    }
  }
`;

export const FirstGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const LastGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 75%;
  height: 470px;

  @media (max-width: 900px) {
    display: none;
  }

  .side-text {
    position: absolute;
    right: -5rem;
    top: 5rem;

    width: 13rem;
    padding: 0.5rem 0.7rem;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.sm};

    color: ${({ theme }) => theme.colors.onPrimary};

    h1 {
      font-size: 0.95rem;
      margin-bottom: 0.25rem;
    }

    p {
      font-weight: 300;
      font-size: 0.85rem;
      line-height: 1.2rem;
    }
  }
`;

export const SolutionContent = styled.div`
  margin: auto 0;

  .card {
    background: ${({ theme }) => theme.colors.overshadowed};
    color: ${({ theme }) => theme.colors.tertiary};
    border-radius: ${({ theme }) => theme.borderRadius.sm};

    padding: 1.5rem;
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 2rem;

      h1 {
        font-size: 1.5rem;
      }

      svg {
        font-size: 2rem;
      }
    }

    p {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
      header {
        h1 {
          text-align: left;
        }
      }

      p {
        text-align: left;
      }
    }
  }

  @media (max-width: 440px) {
    .card {
      height: 220px;
    }
  }
`;

import { FC } from 'react';

import {
  FaRegArrowAltCircleUp,
  FaRegArrowAltCircleDown,
  FaPlusCircle,
  FaRegLaugh,
} from 'react-icons/fa';

import { SectionContainer, SectionTitle } from '~/components/Section';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import { BenefitsContainer, Content, SideBar, VideoContainer } from './styles';

export const Benefits: FC = () => {
  return (
    <SectionContainer id="benefits">
      <Centralized>
        <Content>
          <SectionTitle centralized>
            Assista ao vídeo abaixo para{' '}
            <span className="color-secondary">entender tudo</span>
            <br /> o que a Plataforma Facilita Varejo oferece!
          </SectionTitle>

          <BenefitsContainer>
            <VideoContainer>
              <header>
                <div className="first" />
                <div className="middle" />
                <div className="last" />
              </header>

              <div className="video">
                <iframe
                  title="video"
                  src="/images/benefits/video.mp4"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </VideoContainer>

            <SideBar>
              <div>
                <header>
                  <FaRegArrowAltCircleUp />

                  <h1>Aumentar as suas vendas</h1>
                </header>

                <p>
                  Com a Plataforma Facilita Varejo, o seu cliente compra mais de
                  você. Além de ter o seu delivery próprio e poder vender nos
                  maiores marketplaces, oferecemos integração direta com os
                  melhores PDVs do mercado.
                </p>
              </div>

              <div>
                <header>
                  <FaRegArrowAltCircleDown />

                  <h1>Reduzir os custos do seu estabelecimento</h1>
                </header>

                <p>
                  Através de todo ecossistema, o cliente faz o pedido de
                  diferentes formas sem que haja atendimento de garçom, apenas
                  se necessário. Você reduz o seu custo com equipe e a
                  quantidade de pedidos errados.
                </p>
              </div>

              <div>
                <header>
                  <FaPlusCircle />

                  <h1>Dobrar o lucro do seu negócio</h1>
                </header>

                <p>
                  Sem comissões, taxas ou custos adicionais, através do cardápio
                  digital o valor das suas vendas fica 100% com você. Sem
                  nenhuma surpresa para você no final do mês.
                </p>
              </div>

              <div>
                <header>
                  <FaRegLaugh />

                  <h1>Cliente satisfeito compra mais de você</h1>
                </header>

                <p>
                  Com o cliente tendo mais autonomia no atendimento, os erros
                  nos pedidos são minimizados, o pedido chega mais rápido e o
                  seu cliente fica mais satisfeito. Cliente satisfeito volta,
                  indica e compra mais de você.
                </p>
              </div>
            </SideBar>
          </BenefitsContainer>

          <Button onClick={() => goTo('prices')}>Quero contratar</Button>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

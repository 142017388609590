import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;

  width: 100%;
  background: ${({ theme }) => theme.colors.overshadowed};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin: 4rem 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 2rem;

  @media (max-width: 500px) {
    margin: 1.5rem;
    margin-bottom: 0.5rem;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 20px;

    height: 32px;
    width: 100%;
    left: 0;
    background: #181818;
    border-radius: ${({ theme }) =>
      `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`};

    > div {
      width: 6px;
      height: 6px;
      border-radius: ${({ theme }) => theme.borderRadius.rounded};

      margin-right: 0.7rem;

      &.first {
        background: #c5c5c5;
      }

      &.middle {
        background: #8c8c8c;
      }

      &.last {
        background: #525252;
      }
    }
  }

  .video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    width: 100%;
    border-radius: ${({ theme }) =>
      `0 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm}`};

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const SideBar = styled.div`
  border-left: 1px solid #393939;

  > div {
    position: relative;

    padding: 2rem;
    padding-right: 1rem;

    border-bottom: 1px solid #393939;

    &:last-child {
      border-bottom: none;
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: center;

      color: #b1b1b1;

      margin-bottom: 0.4rem;

      svg {
        min-width: 1.15rem;
        min-height: 1.15rem;
        margin-right: 0.4rem;
      }

      h1 {
        font-size: 0.8rem;
      }
    }

    p {
      color: #8b8b8b;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    transition: all 0.4s;

    &:hover {
      box-shadow: ${({ theme }) =>
        `${theme.colors.primary} 70px 0px 119px -65px inset`};

      &:before {
        content: '';

        position: absolute;
        left: 0;
        top: 0;

        width: 3px;
        height: 100%;
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  @media (max-width: 900px) {
    border-left: 0;

    > div {
      header {
        h1 {
          text-align: left;
        }
      }

      p {
        text-align: left;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @media (max-width: 500px) {
    > div {
      padding: 1.5rem;
    }
  }
`;

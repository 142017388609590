import { FC, useState } from 'react';

import { FaWhatsapp, FaTimes } from 'react-icons/fa';

import { WhatsappButtonContainer } from './styles';

export const WhatsappButton: FC = () => {
  const [visible, setVisible] = useState<boolean>(true);

  return (
    visible && (
      <WhatsappButtonContainer>
        <div>
          <span>Ficou com alguma duvida?</span>
          <strong>Fale com a gente!</strong>
        </div>

        <div className="buttons">
          <a
            href="https://api.whatsapp.com/send?1=pt_br&phone=5511957389071"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>

          <button type="button" onClick={() => setVisible(false)}>
            <FaTimes />
          </button>
        </div>
      </WhatsappButtonContainer>
    )
  );
};

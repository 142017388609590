import { FC, useState } from 'react';

import { HiMenuAlt3 } from 'react-icons/hi';
import { FaTimes } from 'react-icons/fa';

import Link from 'next/link';

import { FACILITA_VAREJO_PORTAL_URL } from '~/constants';

import { useMediaQuery } from '~/hooks/mediaQuery';

import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import {
  HeaderContainer,
  Content,
  NavBar,
  MenuButton,
  CloseMenuButton,
} from './styles';

export const Header: FC = () => {
  const isSmallerThan900px = useMediaQuery('(max-width: 900px)');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onHeaderOptionClick = (goToScreen: string): void => {
    if (isSmallerThan900px) setIsOpen(false);

    goTo(goToScreen);
  };

  return (
    <HeaderContainer id="header-container">
      <Centralized>
        <Content>
          <Image
            src="/images/logos/facilita-varejo-logo.png"
            alt="Plataforma Facilita Varejo"
          />

          {isSmallerThan900px && (
            <MenuButton onClick={() => setIsOpen(true)}>
              <HiMenuAlt3 />
            </MenuButton>
          )}

          <NavBar isOpen={isOpen}>
            {isSmallerThan900px && (
              <CloseMenuButton onClick={() => setIsOpen(false)}>
                <FaTimes />
              </CloseMenuButton>
            )}

            <button
              type="button"
              onClick={() => onHeaderOptionClick('benefits')}
            >
              O que é a solução
            </button>

            <button
              type="button"
              onClick={() => onHeaderOptionClick('forYourBusiness')}
            >
              Pra quem é?
            </button>

            <button type="button" onClick={() => onHeaderOptionClick('prices')}>
              Conheça nossos valores
            </button>

            {/* <button type="button">
              <Link href={POS_FACIL_RESALE_SITE_URL} passHref>
                <a target="_blank">Seja uma revenda</a>
              </Link>
            </button> */}

            <Button>
              <Link href={FACILITA_VAREJO_PORTAL_URL} target="_blank">
                Área do cliente
              </Link>
            </Button>
          </NavBar>
        </Content>
      </Centralized>
    </HeaderContainer>
  );
};

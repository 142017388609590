import { FC } from 'react';

import { useMediaQuery } from '~/hooks/mediaQuery';

import {
  SectionContainer,
  SectionDescription,
  SectionTitle,
} from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import { ComputerContainer, Content } from './styles';

export const Presentation: FC = () => {
  const isSmallerThan900px = useMediaQuery('(max-width: 900px)');

  return (
    <SectionContainer>
      <Content>
        <Centralized
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SectionTitle centralized size="lg">
            A solução digital
            <br /> <span className="color-secondary">mais completa</span> para o
            seu negócio!
          </SectionTitle>

          <SectionDescription centralized size="lg">
            Venda em vários canais. Administre em apenas um lugar.
          </SectionDescription>

          <Button onClick={() => goTo('prices')}>Quero contratar</Button>
        </Centralized>

        <ComputerContainer>
          <div className="arrow marketplaces">
            <span>Marketplaces</span>

            <Image src="/images/presentation/arrow.png" alt="Marketplaces" />
          </div>

          <div className="arrow ecommerce">
            <span>E-commerce</span>

            <Image src="/images/presentation/arrow.png" alt="E-commerce" />
          </div>

          <Centralized className="centralized">
            {isSmallerThan900px ? (
              <Image
                src="/images/presentation/mobile-computer.png"
                alt="Computer"
              />
            ) : (
              <Image src="/images/presentation/computer.png" alt="Computer" />
            )}
          </Centralized>

          <div className="arrow sale-point">
            <span>Ponto de venda</span>

            <Image src="/images/presentation/arrow.png" alt="Ponto de venda" />
          </div>

          <div className="arrow catalog">
            <span>Cardápio Digital</span>

            <Image
              src="/images/presentation/arrow.png"
              alt="Cardápio Digital"
            />
          </div>
        </ComputerContainer>
      </Content>
    </SectionContainer>
  );
};

import { FC } from 'react';

import { MdPlayCircleOutline } from 'react-icons/md';
import { ImQrcode } from 'react-icons/im';
import { FiMonitor } from 'react-icons/fi';

import { useModal } from '~/hooks/modal';
import { useMediaQuery } from '~/hooks/mediaQuery';

import VideoModal from '~/components/VideoModal';
import { SectionContainer } from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import {
  Content,
  Equipaments,
  FirstGroup,
  ImageContainer,
  LastGroup,
} from './styles';
import {
  IntegrationDescription,
  IntegrationFloatText,
  IntegrationNumber,
  IntegrationTextsContainer,
  IntegrationTitle,
} from '../styles';

export const Totem: FC = () => {
  const { openModal } = useModal();
  const isBiggerThan900px = useMediaQuery('(min-width: 900px)');

  return (
    <SectionContainer>
      <Centralized>
        <Content>
          <div>
            <FirstGroup>
              <IntegrationNumber>4</IntegrationNumber>

              <IntegrationTextsContainer>
                <IntegrationTitle>
                  Quebramos a barreira do{' '}
                  <span className="color-secondary">impossível</span> se
                  integrando ao VITRINE TOTEM.
                </IntegrationTitle>

                <IntegrationDescription>
                  O que parecia ser impossível para o pequeno empreendedor hoje
                  é realidade!{isBiggerThan900px && <br />} Com a Plataforma
                  Facilita Varejo, o seu estabelecimento terá uma solução de
                  autoatendimento intuitiva e eficaz,
                  {isBiggerThan900px && <br />} permitindo que você ganhe tempo
                  e mais dinheiro na operação de venda.
                </IntegrationDescription>
              </IntegrationTextsContainer>
            </FirstGroup>

            <LastGroup>
              <ImageContainer>
                <IntegrationFloatText className="ticket-float">
                  <div className="icon top-left">
                    <p style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>$</p>
                  </div>

                  <h1>Aumento do ticket médio</h1>

                  <p>
                    Comprovado aumento
                    <br /> de até <b>30%</b> do ticket médio
                  </p>
                </IntegrationFloatText>

                <Image
                  src="/images/integrations/totem/background.png"
                  alt="Vitrine Totem"
                />

                <IntegrationFloatText className="nfce-float">
                  <div className="icon top-right">
                    <ImQrcode />
                  </div>

                  <h1>Nota fiscal eletrônica</h1>

                  <p style={{ marginTop: 0 }}>
                    Emite a{' '}
                    <b>
                      NFC-e
                      <br /> de forma fácil
                    </b>
                  </p>
                </IntegrationFloatText>

                <IntegrationFloatText className="kds-float">
                  <div className="icon top-right">
                    <FiMonitor />
                  </div>

                  <h1>KDS disponível</h1>

                  <p>
                    Visualize os pedidos
                    <br /> no monitor da cozinha
                  </p>
                </IntegrationFloatText>
              </ImageContainer>

              <Equipaments>
                <li>
                  <h1>Solução em TEF de pagamentos</h1>

                  <div>
                    <div className="solution">
                      <Image
                        src="/images/logos/software-express-white.png"
                        alt="Software Express"
                      />
                    </div>

                    <div className="solution">
                      <Image
                        src="/images/logos/pay-go-white.png"
                        alt="Pay Go"
                      />
                    </div>

                    <div className="solution">
                      <Image
                        src="/images/logos/aditum-white.png"
                        alt="Aditum"
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <h1>Equipamentos homologados</h1>

                  <div>
                    <div className="equipament">
                      <Image src="/images/logos/elgin-white.png" alt="Elgin" />

                      <button
                        type="button"
                        onClick={() =>
                          openModal(
                            'Elgin',
                            <VideoModal videoUrl="https://player.vimeo.com/video/670310900" />
                          )
                        }
                      >
                        Assista ao video <MdPlayCircleOutline />
                      </button>
                    </div>

                    <div className="equipament">
                      <Image
                        src="/images/logos/tec-toy-white.png"
                        alt="Tec Toy"
                      />

                      <button
                        type="button"
                        onClick={() =>
                          openModal(
                            'Tec toy',
                            <VideoModal videoUrl="https://player.vimeo.com/video/670311068" />
                          )
                        }
                      >
                        Assista ao video <MdPlayCircleOutline />
                      </button>
                    </div>
                  </div>
                </li>
              </Equipaments>
            </LastGroup>
          </div>

          <footer>
            <Button onClick={() => goTo('prices')}>Quero contratar</Button>
          </footer>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

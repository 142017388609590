import { FC } from 'react';

import { WrapperImage, StyledImage } from './styles';

type TImage = {
  alt: string;
  src?: any;
  width?: number;
  height?: number;
  objectFit?: 'contain' | 'cover';
  objectPosition?: 'center' | 'top' | 'bottom';
};

export const Image: FC<TImage> = ({
  src,
  width,
  height,
  objectFit,
  objectPosition,
  alt,
}) => {
  if (!src) return null;

  return (
    <WrapperImage width={width} height={height}>
      <StyledImage
        src={src}
        alt={alt}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
    </WrapperImage>
  );
};

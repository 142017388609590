import styled from 'styled-components';

export const Content = styled.div``;

export const FirstGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-right: 2rem;

    @media (max-width: 900px) {
      margin-right: 0;
    }
  }
`;

export const LastGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 900px) {
    grid-template-columns: 1fr;

    .devices-image {
      display: none;
    }
  }
`;

export const Pixels = styled.div`
  margin: auto 0;

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;

    @media (max-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      background: ${({ theme }) => theme.colors.overshadowed};
      border-radius: ${({ theme }) => theme.borderRadius.md};
      overflow: hidden;

      .image {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        width: 6rem;

        padding: 1rem 0.2rem;

        svg {
          color: #fff;
          font-size: 2.5rem;
        }
      }

      > footer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        background: ${({ theme }) => theme.colors.primary};
        padding: 0.35rem 0;

        span {
          color: ${({ theme }) => theme.colors.onPrimary};
          font-size: 0.8rem;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }

  > footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 2rem;

    img {
      width: 100%;
      max-width: 200px;
    }
  }
`;

import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  background: #eee;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 3rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    padding: 2rem;
  }
`;

export const Texts = styled.div`
  p {
    margin: 3rem 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #25d366;
    padding: 0.5rem 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius.xl};

    color: #fff;
    font-weight: bold;

    svg {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }

    transition: all 0.3s;

    &:hover {
      background: #fff;

      color: #25d366;
      font-size: 1.2rem;

      svg {
        color: #25d366;
        font-size: 1.5rem;
      }
    }
  }

  form {
    margin-top: 1.5rem;

    .form-group {
      margin-bottom: 0.5rem;
    }

    > button {
      margin-top: 1.5rem;
    }
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 600px) {
    p {
      margin: 2rem 0;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: -5.5rem;
  width: 100%;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const InputContainer = styled.label`
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.95rem;
  }

  input {
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    border: 1px solid #ccc;
    padding: 0.8rem;

    &.error {
      border-color: #f00;
    }
  }

  .error {
    color: #f00;
    font-size: 0.9rem;
  }
`;

import { FC } from 'react';

import { IoLocationSharp } from 'react-icons/io5';
import { IoMdMail } from 'react-icons/io';
import { ImPhone } from 'react-icons/im';

import Link from 'next/link';

import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';

import { goTo } from '~/utils/goTo';

import { First, FooterContainer, Last } from './styles';

export const Footer: FC = () => {
  return (
    <FooterContainer id="footer-section">
      <Centralized>
        <First>
          <ul>
            <li>
              <button type="button" onClick={() => goTo('benefits')}>
                Beneficios
              </button>
            </li>

            <li>
              <button type="button" onClick={() => goTo('reasons')}>
                Sobre a solução
              </button>
            </li>

            <li>
              <button type="button" onClick={() => goTo('prices')}>
                Plano
              </button>
            </li>

            <li>
              <button type="button">
                <Link
                  href=""
                  onClick={(e) => e.preventDefault()}
                  rel="noopener noreferrer"
                >
                  Termos de uso
                </Link>
              </button>
            </li>

            <li>
              <button type="button">
                <Link
                  href=""
                  onClick={(e) => e.preventDefault()}
                  rel="noopener noreferrer"
                >
                  Políticas de privacidade
                </Link>
              </button>
            </li>
          </ul>

          <ul className="address" style={{ justifyContent: 'start' }}>
            <li className="title">
              <IoLocationSharp />

              <h1>Onde estamos ?</h1>
            </li>

            <li>
              <h2>São Paulo</h2>
              <p>Av. Andrômeda 2000, Barueri, SP, 06473-000</p>
            </li>
          </ul>

          <ul className="phone-numbers">
            <li className="title">
              <ImPhone />

              <h1>Telefone</h1>
            </li>

            <li>
              <span>(11) 95738-9071</span>
            </li>

            <li
              className="title"
              style={{
                marginTop: '1rem',
              }}
            >
              <IoMdMail />

              <h1>E-mail</h1>
            </li>

            <li>
              <span>facilitavarejo@tecban.com.br</span>
            </li>
          </ul>

          <ul className="partners-list">
            <li className="title">
              <h1>Parceiros</h1>
            </li>

            <li className="social-networks">
              <Image
                src="/images/logos/rssolutions-white.png"
                alt="RS Solutions"
              />
            </li>
          </ul>
        </First>
      </Centralized>

      <Last>
        <Centralized className="centralized">
          <Image
            src="/images/logos/facilita-varejo-logo.png"
            alt="Plataforma Facilita Varejo"
          />

          <div>
            <span>
              Copyright &copy; {new Date().getFullYear()} Facilita Varejo
            </span>
            <span>CNPJ: 51.427.102/0001-29</span>
          </div>
        </Centralized>
      </Last>
    </FooterContainer>
  );
};

import { FC, useCallback, useRef, useState } from 'react';

import { FaWhatsapp } from 'react-icons/fa';

import axios from 'axios';
import { useToast } from '@chakra-ui/react';

import { API_URI } from '~/constants';

import { useMediaQuery } from '~/hooks/mediaQuery';

import { Spinner } from '~/components/Spinner';
import {
  SectionContainer,
  SectionDescription,
  SectionTitle,
} from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';

import { maskPhone, unmask } from '~/utils/masks';

import { Content, ImageContainer, InputContainer, Texts } from './styles';

interface IFormErrors {
  name: string;
  email: string;
  phoneNumber: string;
}

export const Help: FC = () => {
  const toast = useToast({
    status: 'error',
    position: 'top-right',
    title: 'Ops, ocorreu um erro ao tentar falar com o consultor',
  });

  const nameRef = useRef(null);
  const emailRef = useRef(null);

  const isSmallerThan1170px = useMediaQuery('(max-width: 1170px)');

  const [step, setStep] = useState<'default' | 'form'>('default');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [formErrors, setFormErrors] = useState<IFormErrors>({} as IFormErrors);
  const [loading, setLoading] = useState(false);

  const clearErrors = useCallback((name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  }, []);

  const handleChangePhoneNumber = useCallback(
    (e) => {
      const { value } = e.target;

      clearErrors('phoneNumber');
      setPhoneNumber(unmask(value));
    },
    [clearErrors]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        setLoading(true);

        if (
          !nameRef.current?.value ||
          !emailRef.current?.value ||
          !phoneNumber
        ) {
          if (!nameRef.current?.value) {
            setFormErrors((prevState) => ({
              ...prevState,
              name: 'Preencha esse campo para continuar',
            }));
          }

          if (!emailRef.current?.value) {
            setFormErrors((prevState) => ({
              ...prevState,
              email: 'Preencha esse campo para continuar',
            }));
          }

          if (!phoneNumber) {
            setFormErrors((prevState) => ({
              ...prevState,
              phoneNumber: 'Preencha esse campo para continuar',
            }));
          }

          return;
        }

        await axios.post(`${API_URI}/auth/send-phone-verification`, {
          sendVerificationCode: false,
          phoneNumber,
          resale: '26',
        });

        setStep('default');
        setPhoneNumber('');

        window.open(
          'https://api.whatsapp.com/send?1=pt_br&phone=5511957389071',
          '_blank'
        );
      } catch (err) {
        if (err?.response?.data?.tag === 'ALREADY_EXISTS_PHONE_NUMBER') {
          setStep('default');
          setPhoneNumber('');

          window.open(
            'https://api.whatsapp.com/send?1=pt_br&phone=5511957389071',
            '_blank'
          );

          return;
        }

        toast();
      } finally {
        setLoading(false);
      }
    },
    [phoneNumber, toast]
  );

  return (
    <SectionContainer>
      <Centralized>
        <Content>
          {step === 'form' ? (
            <Texts>
              <SectionTitle textColor="onLightBackground">
                Para iniciar o atendimento informe os dados abaixo
              </SectionTitle>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <InputContainer htmlFor="name">
                    <span>Nome completo</span>

                    <input
                      id="name"
                      ref={nameRef}
                      className={formErrors.name ? 'error' : ''}
                      onChange={() => clearErrors('name')}
                    />

                    {formErrors.name && (
                      <span className="error">{formErrors.name}</span>
                    )}
                  </InputContainer>
                </div>

                <div className="form-group">
                  <InputContainer htmlFor="email">
                    <span>E-mail</span>

                    <input
                      id="email"
                      ref={emailRef}
                      className={formErrors.email ? 'error' : ''}
                      onChange={() => clearErrors('email')}
                    />

                    {formErrors.email && (
                      <span className="error">{formErrors.email}</span>
                    )}
                  </InputContainer>
                </div>

                <div className="form-group">
                  <InputContainer htmlFor="phoneNumber">
                    <span>Telefone</span>

                    <input
                      id="phoneNumber"
                      value={maskPhone(phoneNumber)}
                      className={formErrors.phoneNumber ? 'error' : ''}
                      onChange={handleChangePhoneNumber}
                    />

                    {formErrors.phoneNumber && (
                      <span className="error">{formErrors.phoneNumber}</span>
                    )}
                  </InputContainer>
                </div>

                <button type="submit">
                  Iniciar conversa <FaWhatsapp />
                </button>
              </form>
            </Texts>
          ) : (
            <Texts>
              <SectionTitle textColor="onLightBackground">
                Sabemos que uma solução com tantos detalhes, pode gerar alguma
                dúvida. Fale conosco para saná-las!
              </SectionTitle>

              <SectionDescription textColor="onLightBackground">
                Clique no botão abaixo e fale agora mesmo com um de nossos
                {!isSmallerThan1170px && <br />} consultores <b>via Whatsapp</b>
              </SectionDescription>

              <button type="button" onClick={() => setStep('form')}>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    Falar com consultor <FaWhatsapp />
                  </>
                )}
              </button>
            </Texts>
          )}

          <ImageContainer>
            <Image
              src="/images/help/phone.png"
              alt="Plataforma Facilita Varejo"
            />
          </ImageContainer>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

import { FC } from 'react';

import {
  MdFastfood,
  MdFreeBreakfast,
  MdHardware,
  MdIcecream,
  MdLocalPizza,
  MdOutlinePhonelinkRing,
  MdStore,
} from 'react-icons/md';
import { IoMdGitNetwork } from 'react-icons/io';
import { ImSpoonKnife } from 'react-icons/im';
import {
  FaBeer,
  FaBook,
  FaCandyCane,
  FaFootballBall,
  FaGlasses,
  FaIndustry,
  FaShoePrints,
  FaShoppingBag,
  FaSmoking,
  FaStoreAlt,
  FaTruck,
  FaTshirt,
} from 'react-icons/fa';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

import {
  SectionContainer,
  SectionDescription,
  SectionTitle,
} from '~/components/Section';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import { ArrowButton, Branch, Branchs, Content } from './styles';

interface IBranch {
  name: string;
  icon: any;
}

export const ForYourBusiness: FC = () => {
  const foodBranchs: IBranch[] = [
    {
      name: 'Restaurante',
      icon: <ImSpoonKnife />,
    },
    {
      name: 'Lanchonete',
      icon: <MdFastfood />,
    },
    {
      name: 'Padaria',
      icon: <MdFreeBreakfast />,
    },
    {
      name: 'Pizzaria',
      icon: <MdLocalPizza />,
    },
    {
      name: 'Sorveteria',
      icon: <MdIcecream />,
    },
    {
      name: 'Conveniência',
      icon: <MdStore />,
    },
    {
      name: 'Adega',
      icon: <FaBeer />,
    },
    {
      name: 'Doceria',
      icon: <FaCandyCane />,
    },
    {
      name: 'Food Truck',
      icon: <FaTruck />,
    },
    {
      name: 'Empórios',
      icon: <FaStoreAlt />,
    },
  ];

  const retailBranchs: IBranch[] = [
    {
      name: 'Loja de roupas',
      icon: <FaTshirt />,
    },
    {
      name: 'Sapataria',
      icon: <FaShoePrints />,
    },
    {
      name: 'Eletrônicos',
      icon: <MdOutlinePhonelinkRing />,
    },
    {
      name: 'Tabacaria',
      icon: <FaSmoking />,
    },
    {
      name: 'Bijouterias',
      icon: <IoMdGitNetwork />,
    },
    {
      name: 'Livrarias',
      icon: <FaBook />,
    },
    {
      name: 'Mercadinhos',
      icon: <FaShoppingBag />,
    },
    {
      name: 'Serviços',
      icon: <MdHardware />,
    },
    {
      name: 'Brinquedos',
      icon: <FaFootballBall />,
    },
    {
      name: 'Industrias',
      icon: <FaIndustry />,
    },
    {
      name: 'Óticas',
      icon: <FaGlasses />,
    },
  ];

  function scroll(side: 'left' | 'right', branchType: 'food' | 'retail'): void {
    const itemElement = document.getElementById(`${branchType}-branch-item`);
    const itemElementWidth = itemElement.offsetWidth;

    const listElement = document.getElementById(`${branchType}-branchs-list`);
    const listElementWidth = listElement.offsetWidth;

    const { scrollLeft } = listElement;
    const scrollTo =
      side === 'right'
        ? scrollLeft + (listElementWidth - itemElementWidth)
        : side === 'left'
        ? scrollLeft - (listElementWidth - itemElementWidth)
        : 0;

    listElement.scrollTo({
      top: 0,
      left: scrollTo,
      behavior: 'smooth',
    });
  }

  return (
    <SectionContainer id="forYourBusiness">
      <Centralized>
        <Content>
          <SectionTitle centralized size="lg">
            Feito para o seu negócio!
          </SectionTitle>
          <SectionDescription centralized size="lg">
            Tudo isso é possível pois temos uma solução completa feita
            especialmente para você que possui.
          </SectionDescription>

          <Branchs>
            <Branch>
              <h1>Food services</h1>

              <div>
                <ArrowButton
                  className="left"
                  onClick={() => scroll('left', 'food')}
                >
                  <AiOutlineDoubleLeft />
                </ArrowButton>

                <ul id="food-branchs-list">
                  {foodBranchs.map((branch) => (
                    <li key={branch.name} id="food-branch-item">
                      {branch.icon}

                      <span>{branch.name}</span>
                    </li>
                  ))}
                </ul>

                <ArrowButton
                  className="right"
                  onClick={() => scroll('right', 'food')}
                >
                  <AiOutlineDoubleRight />
                </ArrowButton>
              </div>
            </Branch>

            <Branch>
              <h1>Varejo</h1>

              <div>
                <ArrowButton
                  className="left"
                  onClick={() => scroll('left', 'retail')}
                >
                  <AiOutlineDoubleLeft />
                </ArrowButton>

                <ul id="retail-branchs-list">
                  {retailBranchs.map((branch) => (
                    <li key={branch.name} id="retail-branch-item">
                      {branch.icon}

                      <span>{branch.name}</span>
                    </li>
                  ))}
                </ul>

                <ArrowButton
                  className="right"
                  onClick={() => scroll('right', 'retail')}
                >
                  <AiOutlineDoubleRight />
                </ArrowButton>
              </div>
            </Branch>
          </Branchs>

          <footer>
            <p>
              Fique tranquilo se não encontrou o seu ramo, fale agora com nossos
              consultores e tire suas dúvidas!
            </p>

            <Button onClick={() => goTo('prices')}>Quero contratar</Button>
          </footer>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

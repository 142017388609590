import { FC } from 'react';

import { SectionContainer } from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import {
  Content,
  FirstGroup,
  ImageContainer,
  LastGroup,
  SolutionContent,
} from './styles';
import {
  IntegrationDescription,
  IntegrationNumber,
  IntegrationTextsContainer,
  IntegrationTitle,
} from '../styles';

export const TabletFacil: FC = () => {
  return (
    <SectionContainer>
      <Centralized>
        <Content>
          <FirstGroup>
            <IntegrationNumber>6</IntegrationNumber>

            <IntegrationTextsContainer>
              <IntegrationTitle>
                Aumente suas vendas com Tablet na mesa!
              </IntegrationTitle>

              <IntegrationDescription>
                Com a <span className="color-secondary">Facilita Varejo</span>,
                você proporciona agilidade no atendimento ao integrar os tablets
                nas mesas, levando autonomia às mãos de seus clientes e
                aumentando suas vendas.
              </IntegrationDescription>
            </IntegrationTextsContainer>
          </FirstGroup>

          <LastGroup>
            <ImageContainer>
              <Image
                src="/images/integrations/tablet-facil/background.png"
                alt="Tablet Fácil"
              />
            </ImageContainer>

            <SolutionContent>
              <div className="card">
                <header>
                  <h1>Pedidos e pagamentos pelo tablet</h1>

                  <Image
                    src="/images/integrations/tablet-facil/touch.png"
                    alt="Tablet Facil"
                  />
                </header>

                <p>
                  Realize pedidos diretamente de um tablet disponível em suas
                  mesas. Com recursos inovadores e intuitivos, o Tablet Fácil
                  oferece conveniência e eficiência, além de possibilitar o
                  pagamento da conta diretamente pelo aplicativo
                </p>
              </div>

              <footer>
                <Button onClick={() => goTo('prices')}>Quero contratar</Button>

                <Image
                  src="/images/logos/tablet-facil.png"
                  alt="Tablet Facil"
                />
              </footer>
            </SolutionContent>
          </LastGroup>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

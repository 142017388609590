import { FC } from 'react';

import { FaChartLine, FaPeopleCarry } from 'react-icons/fa';

import { SectionContainer } from '~/components/Section';
import { Image } from '~/components/Image';
import { Centralized } from '~/components/Centralized';
import { Button } from '~/components/Button';

import { goTo } from '~/utils/goTo';

import { Content, FirstGroup, LastGroup, Recourse } from './styles';
import {
  IntegrationDescription,
  IntegrationFloatText,
  IntegrationNumber,
  IntegrationTextsContainer,
  IntegrationTitle,
} from '../styles';

export const PdvFacil: FC = () => {
  return (
    <SectionContainer>
      <Centralized>
        <Content>
          <div>
            <FirstGroup>
              <header>
                <IntegrationNumber>1</IntegrationNumber>

                <IntegrationTextsContainer>
                  <IntegrationTitle>
                    A Plataforma Facilita Varejo está integrado ao maior e
                    melhor{' '}
                    <span className="color-secondary">solução de PDV</span> do
                    mercado.
                  </IntegrationTitle>

                  <IntegrationDescription>
                    São tantas as vantagens que você vai se surpreender.
                  </IntegrationDescription>
                </IntegrationTextsContainer>
              </header>

              <div className="image-container">
                <IntegrationFloatText className="stock-float">
                  <div className="icon top-left">
                    <FaPeopleCarry />
                  </div>

                  <h1>Controle de estoque</h1>

                  <p>
                    Controle de maneira fácil e<br /> eficiente o seu estoque
                  </p>
                </IntegrationFloatText>

                <Image
                  src="/images/integrations/pdv-facil/background.png"
                  alt="PDV Fácil"
                />

                <IntegrationFloatText className="order-float">
                  <div className="icon top-right">
                    <FaChartLine />
                  </div>

                  <h1>Controle de pedidos</h1>

                  <p>
                    Tire os pedidos de forma fácil e<br /> rápida. Pois cliente
                    feliz compra mais!
                  </p>
                </IntegrationFloatText>
              </div>
            </FirstGroup>

            <LastGroup>
              <Recourse>
                <div className="line" />

                <div className="content">
                  <h1>Sistema PDV + Portal do cliente</h1>

                  <p>
                    Realize suas vendas no meio físico com nosso APP disponível
                    para qualquer maquininha e aparelho android, além de um
                    portal totalmente funcional e simples de ser usado.
                  </p>
                </div>
              </Recourse>

              <Recourse>
                <div className="line" />

                <div className="content">
                  <h1>Conferência de Caixa</h1>

                  <p>
                    Mantenha o fluxo de caixa sempre em dia, sabendo quanto
                    realizou de sangria ou suprimento, conservando o seu
                    dinheiro com um controle mais efetivo.
                  </p>
                </div>
              </Recourse>

              <Recourse>
                <div className="line" />

                <div className="content">
                  <h1>Relatórios e Dashboards</h1>

                  <p>
                    Crie relatórios de vendas, de estoque, e ainda visualize um
                    dashboard moderno e bonito com as principais métricas do seu
                    negócio.
                  </p>
                </div>
              </Recourse>

              <Recourse>
                <div className="line" />

                <div className="content">
                  <h1>Cupom Fiscal</h1>

                  <p>
                    A Plataforma Facilita Varejo é homologado com os principais
                    modelos de cupons fiscais do país. Você processa a venda e
                    em seguida o cupom fiscal é entregue ao cliente.
                  </p>
                </div>
              </Recourse>
            </LastGroup>
          </div>

          <footer>
            <Image src="/images/logos/pdv-facil-white.png" alt="PDV Fácil" />
            <Button onClick={() => goTo('prices')}>Quero contratar</Button>
          </footer>
        </Content>
      </Centralized>
    </SectionContainer>
  );
};

import styled from 'styled-components';

export const WhatsappButtonContainer = styled.div`
  position: fixed;
  bottom: 30px;
  bottom: 30px;
  right: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;

  z-index: 99;

  > div {
    display: flex;
    flex-direction: column;

    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.35);
    border-radius: 5px;
    padding: 6px 10px;
    padding-right: 35px;
    margin-right: -20px;

    text-align: left;
  }

  > .buttons {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #34af23;
    padding: 15px;
    border-radius: 50%;

    &:hover {
      opacity: 1;
    }

    a {
      > svg {
        color: #fff;
        font-size: 40px;
      }
    }

    > button {
      position: absolute;
      right: -2px;
      top: -2px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.8);
      padding: 4px;
      border-radius: 50%;
      border: 1px solid #fff;

      z-index: 9;

      > svg {
        color: #fff;
        font-size: 12px;
      }
    }
  }
`;

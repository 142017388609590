import styled from 'styled-components';

export const Content = styled.div`
  > div {
    display: flex;
    flex-direction: column;
  }

  footer {
    display: flex;
    justify-content: flex-end;

    margin-top: 2rem;
  }
`;

export const FirstGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-right: 2rem;
  }

  @media (max-width: 900px) {
    header {
      margin-right: 0;
    }
  }
`;

export const LastGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  img {
    width: 85%;
  }

  .order-float {
    top: 5%;
    left: -2%;
  }

  .tax-float {
    bottom: 10%;
    right: 10%;

    svg {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 900px) {
    max-width: 500px;
    margin: 0 auto;

    .order-float {
      left: 1%;
    }

    .tax-float {
      right: 1%;
    }
  }
`;

export const MarketplacesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1.5rem;
  align-items: stretch;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.overshadowed};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding: 2rem;
  }

  @media (max-width: 420px) {
    grid-gap: 1rem;

    li {
      padding: 1.2rem;
    }
  }
`;

/* eslint-disable react/no-danger */
import { FC, useCallback, useEffect, useState } from 'react';

import { FaCheck, FaTimes, FaTrophy } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import axios from 'axios';
import { useToast } from '@chakra-ui/react';

import { ADMIN_API_URI, FACILITA_VAREJO_PORTAL_URL } from '~/constants';

import { useFacebookPixel } from '~/hooks/facebookPixel';

import { Button } from '~/components/Button';

import { formatCurrency } from '~/utils/formatCurrency';

import { IPlanRecourse } from '~/interfaces/IPlanRecourse';
import { IPlan } from '~/interfaces/IPlan';

import {
  PlansContainer,
  Content,
  PlansTable,
  DescriptionContainer,
} from './styles';

export const Plans: FC = () => {
  const toast = useToast({
    status: 'error',
    position: 'top-right',
    title: 'Ops, ocorreu um erro ao carregar os planos',
  });
  const { track } = useFacebookPixel();

  const [plans, setPlans] = useState<IPlan[]>();
  const [recourses, setRecourses] = useState<IPlanRecourse[]>();
  const [tooltipVisible, setTooltipVisible] = useState('');
  const [hidePlans, setHidePlans] = useState(true);

  const clickPlan = useCallback(
    (planId) => {
      track('AddToCart');

      if (typeof window?.gtag !== 'undefined') {
        window?.gtag('event', 'conversion', {
          send_to: 'AW-591117712/jvz6COiNiPgCEJD77pkC',
        });
      }

      window.open(
        `${FACILITA_VAREJO_PORTAL_URL}/signup?planId=${planId}&resale=26&origin=pos-facil-site`,
        '_blank'
      );
    },
    [track]
  );

  const handleSubmit = useCallback(
    (plan: IPlan) => {
      clickPlan(plan._id);
    },
    [clickPlan]
  );

  useEffect(() => {
    (async () => {
      try {
        if (!plans || !recourses) {
          const { data: newPlansResponse } = await axios.get(
            `${ADMIN_API_URI}/resales/50cfc726-f6ef-488d-b999-1c8e2fbc16f1/plans?isVisibleInPortal=true`
          );

          if (newPlansResponse) {
            setRecourses(newPlansResponse[0]?.metadata?.content);
            setPlans(newPlansResponse);
          }
        }
      } catch {
        toast();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PlansContainer id="prices">
      <Content>
        <header>
          <div className="title">
            <h1>
              Nosso objetivo é proporcionar facilidade no atendimento a seus
              clientes, e otimização dos recursos
            </h1>
          </div>
          <Button
            style={{ marginTop: '1rem' }}
            onClick={() => setHidePlans(!hidePlans)}
          >
            Obter cotação
          </Button>
        </header>

        <PlansTable style={{ display: hidePlans ? 'none' : 'flex' }}>
          <tr>
            <th>Funcionalidades e integrações</th>

            {recourses &&
              recourses.map((recourse) => (
                <th key={recourse._id}>
                  {recourse.name}

                  <DescriptionContainer
                    onMouseEnter={() => setTooltipVisible(recourse._id)}
                    onMouseLeave={() => setTooltipVisible('')}
                  >
                    <AiOutlineInfoCircle />
                    {tooltipVisible === recourse._id && (
                      <div className="tooltip-container">
                        <h2>{recourse.name}</h2>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: recourse.content,
                          }}
                        />
                      </div>
                    )}
                  </DescriptionContainer>
                </th>
              ))}

            <th>
              Confira a quantidade de integrações e veja como é possível
              controlar 100% a sua operação de forma simples e objetiva com
              apenas uma solução
            </th>
          </tr>

          {plans &&
            plans
              .sort((a, b) => {
                if (a.order > b.order) return 1;

                if (a.order < b.order) return -1;

                return 0;
              })
              .map((plan) => (
                <tr key={plan._id}>
                  <td>
                    {plan.isBestSeller ? (
                      <div className="best-seller">
                        <div className="title">
                          <span className="plan-name">{plan.displayName}</span>
                          <FaTrophy />
                        </div>

                        <p>Campeão de vendas</p>
                      </div>
                    ) : (
                      <span className="plan-name">{plan.displayName}</span>
                    )}
                  </td>

                  {recourses?.map((r) => {
                    const planRecourse = plan.metadata.content.find(
                      (c) => c._id === r._id
                    );

                    return (
                      <td key={r._id}>
                        {planRecourse?.enabled ? (
                          planRecourse.value ? (
                            planRecourse.value
                          ) : (
                            <div className="check-icon">
                              <FaCheck />
                            </div>
                          )
                        ) : (
                          <div className="close-icon">
                            <FaTimes />
                          </div>
                        )}
                      </td>
                    );
                  })}

                  <td>
                    <span className="old-price">Valores a partir de</span>

                    <div className="price">
                      <span>{formatCurrency(plan.minimumAmount)}</span>
                      <span>/mês</span>
                    </div>

                    <span className="old-price">+ taxas de adesão</span>

                    <button type="button" onClick={() => handleSubmit(plan)}>
                      Quero Contratar
                    </button>
                  </td>
                </tr>
              ))}
        </PlansTable>
      </Content>
    </PlansContainer>
  );
};

import styled from 'styled-components';

export const Centralized = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 900px) {
    padding: 0 15px;
  }
`;

import styled from 'styled-components';

export const ButtonContainer = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 1.8rem;
  border-radius: ${({ theme }) => theme.borderRadius.xl};

  color: ${({ theme }) => theme.colors.onPrimary};
  font-weight: bold;
  font-size: 1.1rem;
`;

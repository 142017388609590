import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.colors.onDarkBackground};
      font-size: 0.85rem;

      margin-bottom: 1.5rem;
    }
  }
`;

export const Branchs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  margin: 4rem 0;
  gap: 3rem;
`;

export const Branch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  color: ${({ theme }) => theme.colors.tertiary};

  > div {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;

      width: 100%;
      margin: 0 5px;
      gap: 1rem;

      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-width: 8rem;
        min-height: 8rem;
        padding: 0.5rem;
        background: ${({ theme }) => theme.colors.overshadowed};
        border-radius: ${({ theme }) => theme.borderRadius.sm};

        svg {
          font-size: 2rem;
        }

        span {
          font-size: 0.8rem;

          margin-top: 1rem;
        }
      }
    }
  }

  h1 {
    margin-bottom: 1.5rem;

    position: relative;

    padding: 0.8rem 3rem;
    border-radius: 50%;
    border: ${({ theme }) => `2px solid ${theme.colors.onDarkBackground}`};

    color: ${({ theme }) => theme.colors.onDarkBackground};
    white-space: nowrap;
    font-weight: 300;
    font: 400 1.1rem 'Courgette';

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;

      height: 2.8rem;
      width: 98%;
      border: ${({ theme }) => `2px solid ${theme.colors.onDarkBackground}`};
      border-radius: 50% 60% 50% 50%;

      transform: rotate(3deg);
    }
  }
`;

export const ArrowButton = styled.button`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  min-width: 2.4rem;
  min-height: 2.4rem;
  opacity: 0.8;

  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }

  &.left {
    left: -1rem;
  }

  &.right {
    right: -1rem;
  }

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.onPrimary};
  }
`;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: calc(100vh - 8.4rem);
  margin-top: 4.2rem;

  background-image: url('/images/presentation/background.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  button {
    margin: 2rem 0;
  }

  @media (max-width: 900px) {
    min-height: 0;
  }

  @media (max-width: 650px) {
    background-image: none;
  }
`;

export const ComputerContainer = styled.div`
  position: relative;

  margin-top: auto;
  margin-bottom: 3rem;

  margin-right: 10%;
  margin-left: 10%;

  img {
    width: 100%;
    max-width: 900px;
  }

  .arrow {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;

    span {
      color: #fff;
      font: 400 0.85rem 'Courgette';

      margin-bottom: -10px;
    }

    img {
      width: 150px;
    }

    &.marketplaces {
      top: 10%;
      left: -10%;

      span {
        margin-left: -4px;
      }
    }

    &.ecommerce {
      top: 50%;
      left: -10%;

      span {
        margin-left: -15px;
      }
    }

    &.sale-point {
      top: 10%;
      right: -10%;

      span {
        margin-right: -14px;
      }

      img {
        transform: rotateY(180deg);
      }
    }

    &.catalog {
      top: 50%;
      right: -10%;

      span {
        margin-right: -15px;
      }

      img {
        transform: rotateY(180deg);
      }
    }
  }

  @media (max-width: 900px) {
    width: 100%;

    .centralized {
      padding-right: 0;

      > .styles__WrapperImage-sc-1hgrzig-0 {
        width: 65%;
        max-width: 25rem;
        margin-left: auto;
      }
    }

    .arrow {
      left: 3% !important;
      right: auto !important;

      span {
        margin-right: 0 !important;
        margin-left: -10px !important;
      }

      img {
        transform: rotateY(0) !important;

        width: 125px;
      }

      &.marketplaces {
        top: 10%;
      }

      &.ecommerce {
        top: 35%;

        span {
          margin-left: -20px !important;
        }
      }

      &.sale-point {
        top: 60%;
      }

      &.catalog {
        top: 85%;
      }
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 1rem;
  }
`;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-left: auto;
    margin-top: 2rem;
    width: calc(50% - 1rem);
  }

  @media (max-width: 900px) {
    > div {
      grid-template-columns: 1fr;
    }

    footer {
      width: 100%;
    }
  }
`;

export const FirstGroup = styled.div`
  header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > div {
      @media (min-width: 900px) {
        margin-bottom: 2rem;
      }
    }
  }

  .image-container {
    position: relative;

    .stock-float {
      left: -10%;
      bottom: 15%;
    }

    .order-float {
      right: 0;
      top: 15%;
    }

    img {
      width: 80%;
    }

    @media (max-width: 1400px) {
      .stock-float {
        left: -10px;
        bottom: 10%;
      }
    }

    @media (max-width: 1200px) {
      .order-float {
        right: 0;
        top: 5%;
      }
    }

    @media (max-width: 900px) {
      max-width: 500px;
      margin: 0 auto;

      .order-float {
        right: 15px;
        top: -1rem;
      }

      .stock-float {
        left: 15px;
        bottom: 5%;
      }
    }
  }
`;

export const LastGroup = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
`;

export const Recourse = styled.div`
  position: relative;
  overflow: hidden;

  background: ${({ theme }) => theme.colors.overshadowed};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 1.5rem 2rem;

  transition: all 0.4s;

  &:hover {
    box-shadow: ${({ theme }) =>
      `${theme.colors.primary} 70px 0px 119px -65px inset`};

    &:before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;

      width: 8px;
      height: 100%;
      background: ${({ theme }) => theme.colors.primary};
    }
  }

  .content {
    color: ${({ theme }) => theme.colors.tertiary};

    h1 {
      font-size: 1rem;

      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
      font-weight: 300;
    }
  }

  @media (max-width: 650px) {
    padding: 1.2rem 1.7rem;

    .content {
      h1 {
        margin-bottom: 0.5rem;
      }
    }
  }
`;
